
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Dialog1',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    store: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    copyUrlProps: {
      type: Object,
      default: null,
    },
    url: {
      type: String,
      default: '',
    },
    // app 스킴 type ( product 일 때에만 type :1 로 보내주어야함)
    type: {
      type: String,
      default: null,
    },
    shareItem: {
      type: Object,
      default: () => ({
        title: '',
        description: '',
        imageUrl: '',
        url: '',
        appUrl: '',
      }),
    },
    isProduct: {
      type: Boolean,
      default: false,
    },
    sheetTitle: {
      type: String,
      default: '몰',
    },
  },
  computed: {
    ...mapState('DeviceStore', ['deviceInfo']),
    ...mapState('DeviceStore', ['deviceSettings']),
  },
  methods: {
    ...mapActions('BottomSheetStore', ['fetchOnSheet']),
    sharedKakao() {
      if (
        this.$isMobile() &&
        this.deviceInfo != null &&
        this.deviceInfo.isKakaoAvailable === false
      ) {
        if (this.$device.isAndroid) {
          return window.CellookBridge.moveKakaoMarket()
        }
        if (this.$device.isIOS) {
          return window.webkit.messageHandlers.moveKakaoMarket.postMessage('')
        }
      }

      const { title, description, imageUrl, url, appUrl } = this.shareItem
      // product 일 때에만 type props 1을 받아 보내준다
      let nativeParams = `value=${appUrl ? appUrl : url}&title=${title}${
        this.type !== null && `&type=1`
      }`

      try {
        window.Kakao.Link.sendDefault({
          installTalk: true,
          objectType: 'feed',
          content: {
            title,
            description,
            imageUrl,
            link: {
              mobileWebUrl: url,
              webUrl: url,
              // product 일 때에만 type props 1을 받아 보내준다
              androidExecParams: nativeParams,
              iosExecParams: nativeParams,
            },
          },
          buttons: [
            {
              title: '웹으로 보기',
              link: {
                mobileWebUrl: url,
                webUrl: url,
              },
            },
          ],
        })
      } catch (e) {
        alert(e)
        console.log('kakao shard err', e)
      } finally {
        this.$emit('onClose', 'kakao')
      }
    },
    sharedUrl() {
      let input = this.$refs.copyUrl as HTMLInputElement
      if (navigator.clipboard) {
        navigator.clipboard.writeText(input.value)
        this.fetchOnSheet({ type: 1, title: this.sheetTitle })
        this.$emit('onClose', 'url')
        return
      }
      this.fetchOnSheet({ type: 1, title: this.sheetTitle })
      this.$emit('onClose', 'url')
    },
    sharedMore() {
      const { title, description, imageUrl, url } = this.shareItem
      if (this.deviceInfo === null) {
        this.$emit('onClose', 'etc')
        return
      }
      // ( url: String, isProduct: Boolean, title: String? = null, description: String? = null, imageUrl: String? = null)
      if (this.$device.isAndroid) {
        // window.CellookBridge.shareOtherApp('url', true)
        window.CellookBridge.shareOtherAppUrl(url)
      }
      if (this.$device.isIOS) {
        const payload = {
          url,
        }
        window.webkit?.messageHandlers?.shareOtherAppUrl?.postMessage(payload)
      }
      this.$emit('onClose', 'etc')
    },
  },
})

interface Data {}
interface Methods {
  sharedKakao(): void
  sharedUrl(): void
  sharedMore(): void
  fetchOnSheet({ type }: { type: number; title: string }): void
}
interface Computed {
  deviceInfo: any
  deviceSettings: DeviceSettings
}
interface Props {
  sheetTitle: string
  show: boolean
  store: string
  content: string
  copyUrlProps: {
    copyUrl: string
  }
  url: string
  shareItem: {
    title: string
    description: string
    imageUrl: string
    url: string
    appUrl: string
  }
  type: string | null
  isProduct: boolean
}
