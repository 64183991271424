
import Vue from 'vue'
import PromotionSignUpView from '../../views/event/PromotionSignUp.vue'
export default Vue.extend({
  name: 'ExhibitionEvent',
  components: { PromotionSignUpView },
  data() {
    return {
      scrollLocation: 0,
    }
  },
  created() {
    if (localStorage.getItem('exhibitionEventScrollLocation')) {
      this.scrollLocation = Number(
        localStorage.getItem('exhibitionEventScrollLocation')
      )
    }
    localStorage.removeItem('exhibitionEventScrollLocation')
  },
})
