<!-- 카테고리 -->
<template>
  <div>
    <v-app-bar
      color="default"
      internal-activator
      class="fixed-bar-exhibition-event-header"
      bottom
      flat
      height="44"
    >
      <div class="d-flex justify-space-between fill-width align-center">
        <v-icon small @click="onClickPrevIcon">
          $vuetify.icons.prevIcon
        </v-icon>
        <span
          class="one-line-string rixgo-extrabold align-center gray900--text font-g16-24"
          >[당첨자 발표] 회원가입하고 호캉스 가자!</span
        >
        <div class="pb-1" @click="onClickShareIcon">
          <v-icon>$vuetify.icons.shareIcon</v-icon>
        </div>
      </div>
    </v-app-bar>
    <div class="fill-width">
      <v-img
        :src="
          require('../../../assets/images/event/promotionSignUp/signUpEvent_1.webp')
        "
        height="auto"
      />
      <v-img
        :src="
          require('../../../assets/images/event/promotionSignUp/promotionSignUp2.webp')
        "
        height="auto"
      />
      <v-img
        :src="
          require('../../../assets/images/event/promotionSignUp/signUpEvent_2.webp')
        "
        height="auto"
      />
      <v-img
        :src="
          require('../../../assets/images/event/promotionSignUp/signUpEvent_3.webp')
        "
        height="auto"
      />
      <v-img
        :src="
          require('../../../assets/images/event/promotionSignUp/signUpEvent_4.webp')
        "
        height="auto"
      >
        <div class="fill-width">
          <v-img
            style="transform: scale(0.68); margin-top: 54.5%"
            :src="
              require('../../../assets/images/event/promotionSignUp/signInButton.webp')
            "
            @click="onClickSignIn()"
          ></v-img>
        </div>
        <div>
          <v-img
            style="transform: scale(0.68); margin-top: 28%"
            :src="
              require('../../../assets/images/event/promotionSignUp/eventButton.webp')
            "
            @click="onClickSubmit()"
          ></v-img>
        </div>
      </v-img>
      <v-img
        :src="
          require('../../../assets/images/event/promotionSignUp/signUpEvent_5.webp')
        "
        height="auto"
      />
    </div>
    <Sharing
      :content="shareProps.description"
      :shareItem="shareItem"
      :show="share"
      :url="shareProps.copyUrl"
      :store="shareProps.title"
      sheetTitle=""
      @onClose="share = false"
    />
    <DialogAlertView
      :dialogAlert="dialogAlert"
      :alertText="alertTextHeader"
      :alertDescription="alertTextDescription"
      @alertConfirmClick="alertConfirmClick"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import Sharing from '../../common/dialogs/Sharing.vue'
import DialogAlertView from '../login/DialogAlert.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default Vue.extend({
  name: 'PromotionSignUpView',
  components: {
    Sharing,
    DialogAlertView,
  },
  props: {
    scrollLocation: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      alertTextHeader: '',
      alertTextDescription: '',
      dialogAlert: false,
      share: false,
      shareItem: {
        title: '[이벤트 당첨자 발표] 셀룩 회원가입하고 호캉스 가자!',
        description: '',
        imageUrl: 'https://admin-img.cellook.kr/event/promotion_sign_up.png',
        url: window.location.origin + '/?promotionSignUp=1',
      },
      shareProps: {
        title: '[이벤트 당첨자 발표] 셀룩 회원가입하고 호캉스 가자!',
        description: '',
        copyUrl: window.location.origin + '/?promotionSignUp=1',
      },
    }
  },
  async created() {
    window.addEventListener('scroll', this.handleScroll)
    //shopId에 맞게 JSON 데이터 바인딩
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.setScrollLocation()
  },
  computed: {
    ...mapState('DeviceStore', ['deviceInfo']),
  },
  methods: {
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('ExhibitionStore', ['fetchEventExhibitionScrollLocation']),
    onClickImage(e) {
      console.log(e.offsetX, e.offsetY)
    },
    setScrollLocation() {
      setTimeout(() => {
        window.scrollTo({
          top: this.scrollLocation,
          behavior: 'auto',
        })
      }, 1000)
    },
    onClickPrevIcon() {
      this.$router.push('/')
    },
    onClickShareIcon() {
      this.share = true
    },
    onClickSignIn() {
      if (!this.isLogined) {
        // 로그인창 오픈
        this.fetchLoginDialog({ yn: true })
        return
      } else {
        this.alertTextHeader = '종료된 이벤트입니다.'
        this.dialogAlert = true
      }
    },
    alertConfirmClick() {
      this.dialogAlert = false
    },
    onClickSubmit() {
      this.alertTextHeader = '종료된 이벤트입니다.'
      this.alertTextDescription = ''
      this.dialogAlert = true
      return
    },
    handleScroll() {
      this.fetchEventExhibitionScrollLocation(
        window.scrollY || document.documentElement.scrollTop
      )
    },
    setExhibitionEventScrollLocation() {
      localStorage.setItem(
        'exhibitionEventScrollLocation',
        JSON.stringify(window.scrollY || document.documentElement.scrollTop)
      )
    },
  },
})
</script>
<style scoped>
.fixed-bar-exhibition-event-header {
  position: sticky;
  top: 0em;
  z-index: 102;
}
.bar {
  border-bottom: 1px solid #eaebed;
}
</style>
